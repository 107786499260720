import { ParagraphsController } from "@/paragraphs"

const PageNode = ({ node }) => {
	return (
		<>
			<div className="vactory-paragrphs-wrapper">
				{node.field_vactory_paragraphs &&
					node.field_vactory_paragraphs.map((paragraph) => (
						<ParagraphsController key={paragraph.id} data={paragraph} hasAMP={false} />
					))}
			</div>
		</>
	)
}

export default PageNode

export const config = {
	id: "node--vactory_page",
	params: {
		fields: {
			"node--vactory_page": "field_vactory_paragraphs",
			"paragraph--vactory_component":
				"drupal_internal__id,paragraph_section,paragraph_identifier,paragraph_container,container_spacing,paragraph_css_class,paragraph_background_color,paragraph_background_image,field_vactory_component,field_vactory_title,field_background_color,field_paragraph_hide_lg,field_paragraph_hide_sm,field_position_image_x,field_position_image_y,field_size_image,field_vactory_flag,field_vactory_flag_2,paragraph_background_parallax",
			"paragraph--vactory_paragraph_multi_template":
				"drupal_internal__id,paragraph_section,paragraph_identifier,paragraph_container,container_spacing,paragraph_css_class,paragraph_background_color,paragraph_background_image,field_vactory_component,field_vactory_title,field_paragraph_introduction,field_vactory_paragraph_tab,field_background_color,field_multi_paragraph_type,field_paragraph_hide_lg,field_paragraph_hide_sm,field_position_image_x,field_position_image_y,field_size_image,field_vactory_flag,field_vactory_flag_2,paragraph_background_parallax",
		},
		include:
			"field_vactory_paragraphs,field_vactory_paragraphs.field_vactory_paragraph_tab,field_vactory_paragraphs.paragraph_background_image,field_vactory_paragraphs.paragraph_background_image.thumbnail",
	},
}
