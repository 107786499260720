import dynamic from "next/dynamic"

const ParagraphsMultipleAccordion = dynamic(() => import("./PargaraphsMultipleAccordion"))
const ParagraphsMultipleTab = dynamic(() => import("./pargaraphsMultipleTab"))
const ParagraphsTemplate = dynamic(() => import("./PargaraphsTemplate"))

const ParagraphsMultiple = ({ type = "tab", tabs = [], hasAMP, ...rest }) => {
	const Display = type === "tab" ? ParagraphsMultipleTab : ParagraphsMultipleAccordion
	const components = tabs.map((p, key) => {
		const { field_tab_templates, field_vactory_title, ...paragraphRest } = p
		const widgets = field_tab_templates.map((widget) => {
			const widget_id = widget.widget_id
			const widget_data = JSON.parse(widget.widget_data)

			return (
				<div key={`${widget_id}_${key}`} data-df-id={widget_id}>
					<ParagraphsTemplate id={widget_id} hasAMP={hasAMP} settings={widget_data} />
				</div>
			)
		})

		return {
			...paragraphRest,
			_title: field_vactory_title,
			_widgets: widgets,
		}
	})

	return <Display items={components} {...rest} />
}

export default ParagraphsMultiple
