//import classNames from "classnames"
import { Container } from "@/ui"
import clsx from "clsx"
// import { StatePageSection } from "@gatsby/ui"
// import { Waypoint } from "react-waypoint"

const layoutsMapping = {
	narrow_width: "default",
	full_width: "fluid",
	no_container: "full",
}

const ParagraphsContainer = ({
	children,
	id,
	style,
	layout,
	className,
	hideOnDesktop,
	hideOnMobile,
	backgroundSize,
	backgroundPositionX,
	backgroundPositionY,
	activateParallax,
	spacing,
	//   state,
}) => {
	//const LayoutComponent = layoutsMapping[layout]
	const isBackgroundSolid = style.backgroundColor ? "has-background" : null
	//   let pageSection = StatePageSection.useContainer()
	//   const handleEnter = () => {
	//     if (state) {
	//       pageSection.setCurrentSection(state)
	//     }
	//   }

	//const classTailwind = "bg-contain bg-left bg-right bg-center bg-cover"
	return (
		<Container
			layout="full"
			spacing={spacing}
			className={clsx(
				className,
				isBackgroundSolid,
				hideOnDesktop && !hideOnMobile && "md:hidden",
				hideOnDesktop && hideOnMobile && "hidden",
				!hideOnDesktop && hideOnMobile && "hidden md:block",
				backgroundSize && `bg-${backgroundSize}`,
				backgroundPositionX && `bg-${backgroundPositionX}`,
				backgroundPositionY && `bg-${backgroundPositionY}`,
				activateParallax && "bg-fixed"
			)}
			style={style}
			id={id}
		>
			{/* <Waypoint onEnter={handleEnter} /> */}
			<Container layout={layoutsMapping[layout]}>{children}</Container>
		</Container>
	)
}

export default ParagraphsContainer
